import { useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FiHome, FiGrid, FiPhone } from "react-icons/fi";
import { FaSlack } from "react-icons/fa";
import { BsGear } from "react-icons/bs";
import "./Footer.scss";

function MobileFooter() {
  const [active, setActive] = useState(1);
  return (
    <>
      <Container className="sticky-footer align-self-center py-2 mt-5">
        <div className="d-flex justify-content-between">
          <div className="text-center">
            <Link to="/" className={`sticky-links ${active === 1 ? "active-check" : ""}`} onClick={() => setActive(1)} style={{ transition: "0.3s linear" }}>
              <span style={{ fontSize: "1.5rem" }}>
                <FiHome />
              </span>
            </Link>
          </div>
          <div className="text-center">
            <Link to="/website-development" className={`sticky-links ${active === 2 ? "active-check" : ""}`} onClick={() => setActive(2)}>
              <span style={{ fontSize: "1.6rem" }}>
                <BsGear />
              </span>
            </Link>
          </div>
          <div className="text-center" style={{ marginTop: "-35px" }}>
            <Link to="/get-a-quote" style={{ backgroundColor: "#dc164e", borderRadius: "100px", padding: "12px 16px 15px 16px", color: "white", boxShadow: "0px 0px 8px rgba(0,0,0,0.3)" }}>
              <span style={{ fontSize: "20px" }}>
                <FaSlack />
              </span>
            </Link>
            <p className="mb-0 mt-3" style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
              Quotation
            </p>
          </div>

          <div className="text-center">
            <Link to="/Portfolio" className={`sticky-links ${active === 3 ? "active-check" : ""}`} onClick={() => setActive(3)}>
              <span style={{ fontSize: "1.5rem" }}>
                <FiGrid />
              </span>
            </Link>
          </div>
          <div className="text-center">
            <Link to="/contact-us" className={`sticky-links ${active === 4 ? "active-check" : ""}`} onClick={() => setActive(4)}>
              <span style={{ fontSize: "1.5rem" }}>
                <FiPhone />
              </span>
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
}

export default MobileFooter;
