import { useState } from "react";
import "./Quotation.css";
import { Form } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function Quotation() {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(false);
  const [open, setOpen] = useState();
  const [inCorrect, setinCorrect] = useState("");
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    budget: "",
  });

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    const formData = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      city: data.city,
      budget: data.budget,
    };
    setValidated(true);
    if (formData.name && formData.phone && formData.email) {
      if (isEmailAddress(formData.email)) {
        setBtn(true);
        axios
          .post(process.env.REACT_APP_API_URL + "form", formData)
          .then((data) => {
            console.log("Data Send");
          })
          .catch((error) => {
            console.log("Error" + error);
          });
        setinCorrect("Valid Mail");
        location();
      } else {
        setinCorrect("InValid Mail");
      }
      setData({
        name: "",
        phone: "",
        email: "",
        city: "",
        budget: "",
      });
    }
  };
  const changeName = (e) => {
    setData({
      ...data,
      name: e.target.value,
    });
  };
  const changePhone = (e) => {
    setData({
      ...data,
      phone: e.target.value,
    });
  };
  const changeEmail = (e) => {
    setData({
      ...data,
      email: e.target.value,
    });
  };
  const changeCity = (e) => {
    setData({
      ...data,
      city: e.target.value,
    });
  };
  const changeBudget = (e) => {
    setData({
      ...data,
      budget: e.target.value,
    });
  };

  const location = () => {
    navigate("./thank-you");
  };

  function isEmailAddress(str) {
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(str);
  }

  return (
    <div id="quotation">
      <button className={`btn btn-quote-box ${open ? "active" : ""}`} onClick={() => setOpen(!open)}>
        {open ? "CLOSE" : "Get a Quote"}
      </button>
      {open ? (
        <div className={`card quote-card ${open ? "activeCard" : "closeCard"}`}>
          <h6>Get A Quote</h6>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="validationCustom01">
              <Form.Control required type="text" placeholder="Name*" value={data.name} onChange={changeName} />
              <Form.Control.Feedback type="invalid">!Name Required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom02">
              <Form.Control required type="text" placeholder="Phone*" value={data.phone} onChange={changePhone} />
              <Form.Control.Feedback type="invalid">!Phone Number Required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom03">
              <Form.Control required type="email" placeholder="Email*" value={data.email} onChange={changeEmail} />
              <Form.Control.Feedback type="invalid">!Email Required</Form.Control.Feedback>
              <p style={{ color: "red" }}>{inCorrect}</p>
            </Form.Group>
            <Form.Group controlId="validationCustom04">
              <Form.Control type="text" placeholder="City" value={data.city} onChange={changeCity} />
              <Form.Control.Feedback type="invalid">!City is required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom05">
              <Form.Control type="text" placeholder="Budget" value={data.budget} onChange={changeBudget} />
              <Form.Control.Feedback type="invalid">!Budget is required</Form.Control.Feedback>
            </Form.Group>
            <button type="submit" disabled={btn} className="btn submit-btn">
              {btn ? `Submitted` : `Submit`}
            </button>
          </Form>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Quotation;
