import { BrowserRouter, BrowserRouter as Router } from "react-router-dom";
import Header from "./Components/Header.js";
import Footer from "./Components/Footer.js";
import React, { Suspense, lazy, useState } from "react";
import "./App.css";
import "./career.css";
import WebsiteRoutes from "./Routes/WebsiteRoutes.js";
import MobileFooter from "./Components/mobile-footer.js";
import FooterNew from "./Components/NewFooterMobile/NewFooter";
import Quote from "./Components/Quotationbox/Quotationbox";
import "animate.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import ReactGA from "react-ga4";
ReactGA.initialize("G-VK2NMVSXZ6");

const ScrollToTop = lazy(() => import("./Components/ScrolltoTop"));
const Menu = lazy(() => import("./Components/Menu"));

function App() {
  const [size, setSize] = useState(window.innerWidth);
  window.addEventListener("resize", (event) => {
    setSize(window.innerWidth);
  });
  return (

    <Router>
      <Header />
      <Suspense fallback={<div></div>}>
        <Quote />
        <ScrollToTop />
        <WebsiteRoutes />
        <Menu />
      </Suspense>
      {size > 480 ? <Footer /> : <FooterNew />}
      <MobileFooter />

      <ToastContainer />

    </Router >

  );
}

export default App;
