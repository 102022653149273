import React from 'react'

function first() {
    return (
        <div>
            <h1>This is first</h1>
        </div>
    )
}

export default first;