import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Image,
  Carousel,
  Row,
  Col,
  Card,
  Breadcrumb,
} from "react-bootstrap";
import ProgressBar from "react-scroll-progress-bar";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Helmet } from "react-helmet";
const SEO = () => {
  return (
    <Helmet>
      <title>Timeline - Wellcreator</title>
      <meta name="title" content="Timeline - Wellcreator" />
      <meta
        name="description"
        content="Timeline shows the strugle of wellcreator, you require from Wellcreator"
      />
      <meta
        name="keywords"
        content="Wellcreator, timeline Wellcreator, timeline of wellcreator"
      />
      <link rel="canonical" href="https://www.wellcreator.com/timeline"></link>
    </Helmet>
  );
};

function TimeLine() {
  return (
    <>
    <SEO />
      <div>
        <ProgressBar />
      </div>
      <Container className="generalHero  px-lg-5 px-3 pt-4">
        <div className="d-flex justify-content-start">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Timeline</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Container>
      <div className="py-3 py-lg-5">
        <VerticalTimeline className=" mb-0" lineColor={"#000000"}>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2021 - 2022"
            iconStyle={{ background: "black", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-subtitle">
              About City Book Pakistan
            </h3>
            <p>
              Our Recent Project In Working. It Includes All UI&Ux, Back-end And
              Functionality.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019 - 2021"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Punjab Labour Appellate Tribunal
            </h3>
            <h4 className="vertical-timeline-element-subtitle">Lahore</h4>
            <p>Our First Project With Government Performing At Best.</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2018 - 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">Web Designer</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Technological Developments
            </h4>
            <p>For Best User Experience, Our Team Design Best Solutions.</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2016 - 2018"
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Content Marketing for Web, Mobile and Social Media
            </h3>
            {/* <h4 className="vertical-timeline-element-subtitle">
              Online Course
            </h4> */}
            <p>
              We Use Different Strategies And Social Media To Boost Your
              Business{" "}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2014 - 2016"
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Agile Development Scrum Master
            </h3>

            <p>
              Where we collect requirements make solutions and evolve through
              collaboration
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2012 - 2014"
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Wellcreator Establishment
            </h3>
            <h4 className="vertical-timeline-element-subtitle"></h4>
            <p>
              Software Development Company, Providing Services In Software,
              Websites And Game Development fields.{" "}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
          />
        </VerticalTimeline>{" "}
      </div>
    </>
  );
}

export default TimeLine;
